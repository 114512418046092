<template>
  <div class="flexCol alignItems">
    <div class="content">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>系统管理</el-breadcrumb-item>
        <el-breadcrumb-item>账号管理</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="content_li">
        <div class="top-search">
          <div class="flexRow justifyContentSpac">
            <div class="flexRow">
              <div class="flexRow alignItems margin-btn">
                <div class="search-title">姓名：</div>
                <el-input v-model="search.nickName" placeholder="搜索"></el-input>
              </div>
              <div class="flexRow alignItems margin-btn">
                <div class="search-title">账号：</div>
                <el-input v-model="search.loginName" placeholder="搜索"></el-input>
              </div>
              <el-button type="primary" @click="searchData" style="margin-left:20px;">搜索</el-button>
              <el-button @click="initSearch" style="margin-left:20px;">重置</el-button>
            </div>
            <div class="flexRow">
              <el-button type="primary" v-if="accountAdd" @click="goAdd">新增账号</el-button>
            </div>
          </div>
        </div>
        <div class="table-content">
          <el-table
              border
              :data="tableList"
              v-loading="tableLoading"
              style="width: 100%"
              :header-cell-style="this.utils.getRowClass"
          >
            <el-table-column align="center" type="index" label="序号" width="80"></el-table-column>
            <el-table-column align="center" prop="loginName" label="账户"></el-table-column>
            <el-table-column align="center" prop="nickName" label="姓名"></el-table-column>
            <el-table-column align="center" prop="phone" label="手机号"></el-table-column>
<!--            <el-table-column align="center" prop="couponName" label="性别"></el-table-column>-->
<!--            <el-table-column align="center" prop="couponName" label="紧急联系人"></el-table-column>-->
<!--            <el-table-column align="center" prop="couponName" label="紧急联系人手机号"></el-table-column>-->
            <el-table-column align="center" label="头像" min-width="60">
              <template slot-scope="scope"><img @click="handleSee(scope.row.avatar)" class="icon-img"
                                                :src="scope.row.avatar"/></template>
            </el-table-column>
            <el-table-column align="center" prop="createUserName" label="创建人" min-width="60"></el-table-column>
            <el-table-column align="center" prop="updateUserName" label="最后修改人" min-width="60"></el-table-column>
            <el-table-column align="center" label="操作" min-width="150">
              <template slot-scope="scope">
                <div class="flex flex_row flex_ju_center">
                  <div class="border_center_left" v-if="accountUpd" @click="edit(scope.row)">编辑</div>
                  <div class="border_center_left" v-if="accountDel" @click="del(scope.row.id)">删除</div>
                  <div class="border_center_left" v-if="accountUser" @click="menuSort(scope.row)">用户角色关联</div>
                  <div class="border_center_left" @click="details(scope.row)">详情</div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="flexRow justifyContentSpac" style="margin-top:20px">
            <div></div>
            <el-pagination
                @size-change="handleSizeChange"
                background
                layout="total, prev, pager, next,sizes"
                :total="total"
                :page-sizes="[10, 15, 20, 30, 50, 100]"
                :page-size="pageSize"
                @current-change="changePage"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
        :title="type === 'details' ? '详情' : type === 'edit' ? '修改' : '新增'"
        :close-on-click-modal="false"
        :visible.sync="dialogVisible"
        width="60%"
        :before-close="handleClose"
    >
      <el-form v-if="upData && formDataReady" class="flexGrid" :model="upData" :rules="rules" ref="ruleForm">
        <el-form-item label="姓名：" prop="nickName">
          <span v-if="type === 'details'">{{upData.nickName}}</span>
          <el-input v-else v-model="upData.nickName" placeholder="请输入员工姓名"></el-input>
        </el-form-item>
        <el-form-item label="账号：" prop="loginName">
          <span v-if="type === 'details'">{{upData.loginName}}</span>
          <el-input v-else v-model="upData.loginName" placeholder="请输入员工账号"></el-input>
        </el-form-item>
        <el-form-item label="密码：" prop="reqPassword" v-if="type === 'add'">
          <el-input type="password" v-model="upData.reqPassword" placeholder="请输入账号密码"></el-input>
        </el-form-item>
        <el-form-item label="重复密码：" prop="passwords" v-if="type === 'add'">
          <el-input type="password" v-model="upData.passwords" placeholder="请再次输入密码"></el-input>
        </el-form-item>
        <el-form-item label="修改密码：" v-if="type === 'edit'">
          <el-input type="password" v-model="upData.reqPassword" placeholder="请输入账号密码"></el-input>
        </el-form-item>
        <el-form-item label="重复密码：" v-if="type === 'edit'">
          <el-input type="password" v-model="upData.passwords" placeholder="请再次输入密码"></el-input>
        </el-form-item>
        <el-form-item class="zhengjian2" label="手机号：" prop="phone">
          <span v-if="type === 'details'">{{upData.phone}}</span>
          <el-input v-else v-model="upData.phone" placeholder="请输入手机号" type="tel" maxlength="11"></el-input>
        </el-form-item>
<!--        <el-form-item label="身份证号：" prop="idCardNo">-->
<!--          <el-input type="text" maxlength="18" v-model="upData.idCardNo" placeholder="请输入身份证号"-->
<!--                    @change="checkNumber"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="年龄：" prop="age">-->
<!--          <el-input type="tel" v-model="upData.age" placeholder="输入您的身份证号码自动计算" disabled></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="性别：" prop="gender">-->
<!--          <el-select v-model="upData.gender" remote disabled reserve-keyword placeholder="请选择性别">-->
<!--            <el-option label="男" value="1"></el-option>-->
<!--            <el-option label="女" value="2"></el-option>-->
<!--          </el-select>-->
<!--        </el-form-item>-->
<!--        <el-form-item class="zhengjian2" label="紧急联系人：" prop="emergencyContact">-->
<!--          <el-input v-model="upData.emergencyContact" placeholder="请输入紧急联系人姓名"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item class="zhengjian2" label="紧急联系人联系方式：" prop="emergencyPhone">-->
<!--          <el-input type="tel" v-model="upData.emergencyPhone" placeholder="请输入紧急联系人联系方式" maxlength="11"></el-input>-->
<!--        </el-form-item>-->
        <el-form-item label="头像：" prop="avatar">
          <div class="flexRow images-item">
            <div class="up-image" v-if="type === 'details'">
              <div class="image-list">
                <img @click="handleSee(upData.avatar)" :src="upData.avatar" alt/>
              </div>
            </div>
            <div class="up-image" v-else>
              <div v-for="(item, index) in upList" class="image-list" :key="index">
                <img @click="handleSee(item.url)" :src="item.url" alt/>
                <i class="el-icon-error" @click="removeImage(index)"></i>
              </div>
            </div>
            <el-upload
                v-if="type !== 'details'"
                action
                accept=".jpg, .jpeg, .png, .gif"
                :on-exceed="onExceed"
                :on-error="onError"
                :file-list="upList"
                list-type="picture-card"
                :show-file-list="false"
                :auto-upload="true"
                :http-request="fnUploadRequest"
                :before-upload="beforeFileUpload"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </div>
        </el-form-item>

      </el-form>
      <span v-if="type === 'details'" slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleClose">返 回</el-button>
      </span>
      <span v-else slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="add" v-if="type !== 'details'">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 分配权限 -->
    <el-dialog title="用户权限分配" :visible.sync="menuProp" width="30%" :close-on-click-modal="false"
               @close="menuProp = false">
      <el-tree :props="prop" ref="Tree" node-key="id" :load="loadNodeMenu" lazy>
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <el-checkbox @change="changeMenuList(data)" v-model="data.hasRole" :label="data.name"
                         :value="data.id"></el-checkbox>
          </span>
      </el-tree>
    </el-dialog>


    <el-dialog :visible.sync="dialogImgVisible">
      <div class="flexRow justifyContentCenter alignItems">
        <img width="70%" :src="dialogImgUrl" alt=""></div>
    </el-dialog>
  </div>
</template>

<script>

export default {
  data() {
    const validatorAvatar = (rule, value, callback) => {
      if (this.upData.avatar === "") {
        callback(new Error("请上传头像"));
      } else {
        callback();
      }
    }
    const checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("手机号不能为空"));
      } else {
        const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error("请输入正确的手机号"));
        }
      }
    };
    const checkIdCard = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("身份证号不能为空"));
      } else {
        const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error("请输入正确的身份证号码"));
        }
      }
    };
    const validatorPassword = (rule, value, callback) => {
      // let reg = /(?=^.{6,18}$)(?=.*\d)(?=.*\W+)(?=.*[a-zA-Z])(?!.*\s).*$/;
      let reg = /^\w{3,18}$/;
      if (value === "") {
        callback(new Error("请输入账号密码"));
      } else if (!reg.test(value)) {
        // callback(new Error("密码必须包含字母,数字和特殊字符,长度6-18位"));
        callback(new Error("长度为3-18位"));
      } else {
        callback();
      }
    };
    const validatorPasswords = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("重复密码不能为空"));
      } else if (value !== this.upData.reqPassword) {
        callback(new Error("和设置的密码不一致"));
      } else {
        callback();
      }
    };
    //
    //
    return {
      search: {
        nickName: "",
        loginName: "",
      },
      type: "",
      tableList: [],
      upList: [],
      dialogVisible: false,
      dialogImgVisible: false,
      tableLoading: false,
      btnLoading: false,
      formDataReady: true,
      dialogImgUrl: "",
      rules: {
        nickName: [{required: true, message: "请输入员工姓名", trigger: "blur"}],
        loginName: [{required: true, message: "请输入员工账号", trigger: "blur"}],
        avatar: [{required: true, validator: validatorAvatar, trigger: "change"}],
        phone: [{required: true, validator: checkPhone, trigger: "change"}],
        reqPassword: [{required: true, validator: validatorPassword, trigger: "change"}],
        passwords: [{required: true, validator: validatorPasswords, trigger: "change"}],
        // idCardNo: [{required: true, validator: checkIdCard, trigger: "change"}],
        // emergencyContact: [{required: true, message: "请输入紧急联系人", trigger: "blur"}],
        // emergencyPhone: [{required: true, validator: checkPhone, trigger: "change"}],
        // age: [{required: true, message: "请输入身份证号码自动获取", trigger: "blur"}],
        // gender: [{required: true, message: "请输入身份证号码自动获取", trigger: "blur"}]
      },
      upData: {
        nickName: "",
        loginName: "",
        phone: "",
        reqPassword: "",
        passwords: "",
        // gender: "",
        // idCardNo: "",
        // age: "",
        // avatar: "",
        // emergencyContact: "",
        // emergencyPhone: "",
      },
      accountAdd: false,
      accountDel: false,
      accountUpd: false,
      accountUser: false,
      total: 0,
      page: 1,
      pageSize: 10,
      datePicker: [],
      datePicker1: [],
      // 关联角色start
      prop: {
        children: 'zones',
        label: 'name',
      }, //树形结构参数
      menuProp: false,
      node: [],
      resolve: [],
      menuDetail: [],
      authList: [],//按钮权限
      contractList: [],
      defaultCheckedKeys: [],
      // 关联角色end
    };
  },
  mounted() {
    this.getMenuBtn();
    this.getData();
  },
  methods: {
    getData() {
      let _this = this;
      _this.tableLoading = true;
      const requestData = {
        page: this.page,
        pageSize: this.pageSize,
        search: {}
      }
      // 过滤筛选
      const filterData = JSON.parse(JSON.stringify(this.search));
      for (let key in filterData) {
        if (filterData[key]) {
          requestData.search[key] = filterData[key];
        }
      }


      _this.$api
          .getStaff(requestData)
          .then(res => {
            if (res.data.code == 200) {
              _this.total = res.data.data.total;
              const data = JSON.parse(JSON.stringify(res.data.data.records));
              _this.tableList = data;
              _this.tableLoading = false;
            }
          }).catch(() => {
        _this.tableLoading = false;
      });
    },
    // 获取按钮权限
    getMenuBtn() {
      let _this = this;
      const list = JSON.parse(localStorage.getItem("jurisdictionList"));

      list.forEach((v) => {
        if (v == "account:add") {
          _this.accountAdd = true;
        }
        if (v == "account:del") {
          _this.accountDel = true;
        }
        if (v == "account:upd") {
          _this.accountUpd = true;
        }
        if (v == "account:user") {
          _this.accountUser = true;
        }
      })
    },
    // 初始化表单
    handleInitForm() {
      let _this = this;
      _this.formDataReady = false;
      _this.$nextTick(() => {
        _this.formDataReady = true;
      });
    },
    // 浏览图片
    handleSee(e) {
      let _this = this;
      _this.dialogImgUrl = e;
      _this.dialogImgVisible = true;
    },
    // 有效期
    changePicker(e) {
      this.search.effectiveStart = e[0];
      this.search.effectiveEnd = e[1];
    },
    changePicker1(e) {
      this.upData.effectiveStart = e[0];
      this.upData.effectiveEnd = e[1];
    },
    add() {
      let _this = this;
      _this.btnLoading = true;
      _this.$refs["ruleForm"].validate(valid => {
        if (valid) {
          const resquestData = JSON.parse(JSON.stringify(_this.upData))
          delete resquestData.passwords

          if (!_this.upData.id) {
            _this.$api.addStaff(resquestData).then(res => {
              if (res.data.code == 200) {
                _this.$message.success("添加成功");
                _this.btnLoading = false;
                _this.handleClose();
                _this.getData();
              }
            }).catch(() => {
              _this.btnLoading = false;
            });
          } else {
            _this.$api.editStaff(resquestData).then(res => {
              if (res.data.code == 200) {
                _this.$message.success("修改成功");
                _this.btnLoading = false;
                _this.getData();
                _this.handleClose();
              }
            }).catch(() => {
              _this.btnLoading = false;
            });
          }
        } else {
          _this.btnLoading = false;
        }
      });
    },
    handleClose() {
      let _this = this;
      _this.upData = {
        nickName: "",
        loginName: "",
        phone: "",
        password: "",
        passwords: "",
        // gender: "",
        // idCardNo: "",
        // age: "",
        // avatar: "",
        // emergencyContact: "",
        // emergencyPhone: "",
      }
      _this.handleInitForm();
      _this.dialogVisible = false;
    },
    changePage(e) {
      let _this = this;
      _this.page = e;
      _this.getData();
    },
    handleSizeChange(e) {
      let _this = this;
      _this.page = 1;
      _this.pageSize = e;
      _this.getData();
    },
    initSearch() {
      let _this = this;
      _this.search = {
        nickName: "",
        loginName: "",
      }
      _this.page = 1;
      _this.datePicker = [];
      _this.getData();
    },
    searchData() {
      let _this = this;
      _this.page = 1;
      _this.getData();
    },

    goAdd() {
      let _this = this;
      _this.dialogVisible = true;
      _this.type = 'add';
      _this.upData = {
        nickName: "",
        loginName: "",
        phone: "",
        password: "",
        passwords: "",
        // gender: "",
        // idCardNo: "",
        // age: "",
        // avatar: "",
        // emergencyContact: "",
        // emergencyPhone: "",
      }
      _this.upList = [];
      _this.datePicker1 = [];
      _this.handleInitForm();
    },
    details(e) {
      let _this = this;
      _this.dialogVisible = true;
      _this.type = 'details';
      _this.upData = JSON.parse(JSON.stringify(e));
      _this.upList = [];
      _this.upList.push({
        url: _this.upData.avatar
      });
    },
    edit(e) {
      let _this = this;
      _this.dialogVisible = true;
      _this.type = 'edit';
      _this.upData = JSON.parse(JSON.stringify(e));
      _this.upList = [];
      if(_this.upData.avatar) {
        _this.upList.push({
          url: _this.upData.avatar
        });
      }

    },
    del(id) {
      let _this = this;
      _this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info"
      })
          .then(() => {
            _this.$api.delStaff({id}).then(res => {
              if (res.data.code == 200) {
                if (res.data.data) {
                  _this.$message.success('删除成功');
                  _this.getData();
                } else {
                  _this.$message.error('删除失败');
                }
              }
            });
          })
          .catch(() => {
            _this.$message.error('删除失败');
          });
    },
    // 计算年龄
    checkNumber(e) {
      var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
      if (!reg.test(e)) {
        this.$message("请输入正确的身份证号码");
      } else {
        var len = (e + "").length;
        if (len == 0) {
          return 0;
        } else {
          if (len != 15 && len != 18) {
            //身份证号码只能为15位或18位其它不合法
            return 0;
          }
        }
        var strBirthday = "";
        if (len == 18) {
          //处理18位的身份证号码从号码中得到生日和性别代码
          strBirthday = e.substr(6, 4) + "/" + e.substr(10, 2) + "/" + e.substr(12, 2);
        }
        if (len == 15) {
          strBirthday = "19" + e.substr(6, 2) + "/" + e.substr(8, 2) + "/" + e.substr(10, 2);
        }
        //时间字符串里，必须是“/”
        var birthDate = new Date(strBirthday);
        var nowDateTime = new Date();
        var age = nowDateTime.getFullYear() - birthDate.getFullYear();
        //再考虑月、天的因素;.getMonth()获取的是从0开始的，这里进行比较，不需要加1
        if (
            nowDateTime.getMonth() < birthDate.getMonth() ||
            (nowDateTime.getMonth() == birthDate.getMonth() && nowDateTime.getDate() < birthDate.getDate())
        ) {
          age--;
        }
        this.upData.age = age;

        if (parseInt(e.substr(17, 1)) % 2 == 1) {
          this.upData.gender = "1";
        } else {
          this.upData.gender = "2";
        }
      }
    },
    // 关联角色
    menuSort(item) {
      let _that = this
      _that.menuDetail = item;
      _that.requestNewTree()
      _that.menuProp = true
    },
    //角色权限
    requestNewTree() {
      this.node.childNodes = [] //把存起来的node的子节点清空，不然会界面会出现重复树！
      this.loadNodeMenu(this.node, this.resolve) //再次执行懒加载的方法
    },
    loadNodeMenu(node, resolve) {
      let _that = this
      _that.defaultCheckedKeys = []
      if (node.level === 0) {
        this.node = node
        this.resolve = resolve
        let data = {
          aimId: _that.menuDetail.id,
          parent: -1,
        }
        _that.$api.roleGl(data).then((res) => {
          if (res.data.code == 200) {
            let newData = res.data.data
            for (let index = 0; index < newData.length; index++) {
              if (newData[index].hasRole) {
                _that.defaultCheckedKeys.push(newData[index].id)
              }
            }
            resolve(newData)
          } else {
            _that.utils.error(res.data.message)
          }
        })
      }
      if (node.level >= 1) {
        let data = {
          aimId: _that.menuDetail.id,
          parent: node.data.id,
        }
        _that.$api.roleGl(data).then((res) => {
          if (res.data.code == 200) {
            let oldDate = res.data.data
            for (let index = 0; index < oldDate.length; index++) {
              if (oldDate[index].hasRole) {
                _that.defaultCheckedKeys.push(oldDate[index].id)
              }
            }
            resolve(oldDate)
          } else {
            _that.utils.error(res.data.message)
          }
        })
      }
    },
    changeMenuList(e) {
      let _that = this
      let data = {}
      if (e.hasRole) {
        data = {
          aimId: _that.menuDetail.id,
          targetId: ['add:' + e.id], //新增
        }
      } else {
        data = {
          aimId: _that.menuDetail.id,
          targetId: ['del:' + e.id], //删除
        }
      }
      _that.$api.roleGanet(data).then((res) => {
        if (res.data.code == 200) {
          if (e.hasRole) {
            _that.utils.success('新增成功')
          } else {
            _that.utils.success('删除成功')
          }
        } else {
          _that.utils.error(res.data.message)
        }
      })
    },
    // 上传start
    handleRemove() {
      let _this = this;
      _this.avatar = [];
    },
    handlePictureCardPreview(file) {
      let _this = this;
      _this.dialogImageUrl = file.url;
      _this.dialogVisible = true;
    },
    onExceed() {
      let _this = this;
      _this.$message.error("图片最多上传2张");
    },
    removeImage(e) {
      let _this = this;
      _this.upList.splice(e, 1);
      _this.upData.avatar = "";
    },
    onError() {
      let _this = this;
      _this.$message.error("图片大小不能超过2M");
    },
    beforeFileUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      let _this = this;

      if (!isLt2M) {
        _this.$message.error('上传图片大小不能超过 2MB!');
      }
      return isLt2M;
    },
    fnUploadRequest(e) {
      let _this = this;
      _this.utils.upFile(e.file).then(res => {
        _this.upList = [];
        _this.upList.push(res);
        _this.upData.avatar = res.url;
      });
    }
    // 上传end
  }
};
</script>
<style scoped lang="less">
/deep/ .el-form-item {
  width: 90%;
}

/deep/ .el-input__count {
  background-color: #f8fafb;
}

.between-text {
  margin: 0 10px;
}

.mr-top {
  margin-top: 20px;
}

/deep/ .el-input-number {
  width: 100%;
}

/deep/ .el-range-editor {
  width: 100%;
}

/deep/ .el-input-number .el-input__inner {
  text-align: left;
}

.flexGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  //margin: 20px 0;

  /deep/ .el-form-item {
    flex-direction: column;
    justify-content: space-between;
  }
}

/deep/ .el-range-input {
  background-color: #fff;
}

.images-item {
  width: 100%;
}
/deep/ .el-date-editor {
  width: 100%;
}

</style>
